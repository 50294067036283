<template>
  <div class="">
    <heade></heade>
    <div class="com" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="com_box">
        <div>
          <div class="com-left">
            <div class="com-left-top">
              {{ practicesDivision.name }}
            </div>
            <div class="com-left-foot nice-scroll">
              <div v-for="(items, indexs) in practicesDivision.division_category" :key="indexs">
                <div class="com-left-com-item" v-if="practicesDivision.division_category.length > 1">
                  {{ items.name }}
                </div>
                <div v-for="(itemst, indexst) in items.division" :key="indexst" @click="
                  getDivisionContentByContentId(itemst.division_content_id)
                  " :id="'trigger' + indexst" v-trigger :class="queryWhere.id == itemst.division_content_id
                    ? 'com-left-foot-but active'
                    : 'com-left-foot-but'
                    ">
                  {{ itemst.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="com-right">
            <div class="main_content" v-html="contentDetail.main_content"></div>
          </div>
        </div>
        <div class="com-btt">
          <div>
            <div>
              <a v-for="(item, index) in practicesMedicines[0].course_division" :key="index" :class="item.id == queryWhere.previous
                ? 'com-bbt-item active'
                : 'com-bbt-item'
                ">
                {{ item.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import heade from "@/components/heade.vue";
import foot from "@/components/foot.vue";
import { scroll } from "@utils/scroll";
import {
  getPracticeMedicine,
  getDivisionContentByContentId,
} from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
    practicesDivision() {
      this.$nextTick(function () {
        scroll($(".nice-scroll"));
      });
    },
  },
  props: {},
  data() {
    return {
      selected: 0,
      practicesDivision: {},
      queryWhere: {},
      content: "",
      backgroud: "",
      detailPath: "",
      path: "",
      pathUrl: "",
      contentDetail: {},
      practicesMedicines: {},
    };
  },
  mounted: function () {
    let that = this;
    //调用接口的方法
    getPracticeMedicine(that.queryWhere).then(function (res) {
      that.$set(that, "practicesDivision", res.data);
      that.$set(that, "practicesMedicines", res.data.medicines_by_grade.practice_medicines.course_division_category);
    });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    this.detailPath = this.$route.meta.detailPath;
    this.path = this.$route.meta.path;
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
  methods: {
    change(index) {
      this.selected = index;
    },
    go(src) {
      window.location.href = src;
    },
    getDivisionContentByContentId: function (id) {
      let that = this;
      this.queryWhere.id = id;
      getDivisionContentByContentId({ id: id }).then(function (res) {
        that.$set(that, "contentDetail", res.data);
      });
      scroll($(".nice-scroll"));
      // 实现滚动效果
      let top = document.getElementsByClassName("com-right")[0].scrollTop;
      const timeTop = setInterval(function () {
        document.getElementsByClassName("com-right")[0].scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
  updated: function () {
    layui.use(["table"], function () {
      var table = layui.table;
    });
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
  directives: {
    trigger: {
      inserted(el, binging) {
        el.id == "trigger0" ? el.click() : null;
      },
    },
  },
};
</script>
<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.com_box {
  width: 1484px;
  height: 1660px;
  position: relative;
  margin: 0 auto;
}

.com-left-com-item {
  width: 100%;
}

.com-right-div {
  height: 20px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.com-right-s {
  display: none;
}

.com_right {
  overflow-y: auto;
}

.com-btt>div {
  width: 15px;
}

.com-btt {
  width: 940px;
  font-size: 14px;
}

.com-btt>div>div {
  width: 700px;
  height: 260px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 160px;
  top: 8px;
  /* border: 1px solid red; */
}

.com_right {
  overflow-y: auto;
}

.com-left-foot {
  height: 350px;
  overflow-y: auto;
}

/* 隐藏滚动条 */
.com-left-foot::-webkit-scrollbar {
  display: none;
}

.com-bbt-item {
  width: 15px;
  font-size: 18px;
  line-height: 20px;
}

.com-right-but {
  width: 100%;
  height: 658px;
}

.active {
  color: #900c0c;
}

.active2 {
  color: red;
}

.actived_content {
  display: block;
}

.division_ul {
  display: flex;
  justify-content: space-between;
  margin: 40px 30px;
}

.content {
  overflow-x: auto;
}

.content::-webkit-scrollbar {
  display: none;
}

.com-left-foot-but {
  line-height: 30px !important;
}

.com-left-foot-but.active {
  color: #d8c8a5;
}

.com-right {
  overflow-y: auto;
}

.com-right .content p>span {
  font-size: 16px !important;
}

.com-left-top {
  height: 200px !important;
}

.com-left-com-item {
  margin: 15px auto;
}

.main_content {
  padding: 25px 5px;
}
</style>
